<template>
  <a-card :border="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 8 }" :wrapper-col="{ xs: 16 }">
        <a-row :gutter="8">
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="预订单状态">
              <DictSelect
                field="advanceOrder"
                :value.sync="searchData.orderType"
                style="width: 100%"
                placeholder="请选择订单状态"
              ></DictSelect>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="申请状态">
              <DictSelect
                field="registrationStatus"
                :value.sync="searchData.registrationStatus"
                style="width: 100%"
                placeholder="请选择申请状态"
              ></DictSelect>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="员工">
              <a-select placeholder="请选择员工" v-model="searchData.managerId" allowClear>
                <a-select-option :value="item.id" v-for="(item, index) in managerData" :key="index">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="客户名称">
              <a-input v-model="searchData.name" placeholder="客户名称" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="联系方式">
              <a-input v-model="searchData.phone" placeholder="联系方式" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="所在地区">
              <PCD
                ref="pcd"
                placeholder="请选择区域"
                :province.sync="searchData.province"
                :city.sync="searchData.city"
                :district.sync="searchData.area"
                :provinceId.sync="searchData.provinceId"
                :cityId.sync="searchData.cityId"
                :districtId.sync="searchData.areaId"
              >
              </PCD>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="物业地址">
              <a-input v-model="searchData.address" placeholder="小区/栋/单元/室" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="申请日期">
              <DateRange
                :startTime.sync="searchData.createTimeStart"
                :endTime.sync="searchData.createTimeEnd"
              ></DateRange>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="成交日期">
              <DateRange :startTime.sync="searchData.dealTimeStart" :endTime.sync="searchData.dealTimeEnd"></DateRange>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="4" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
        <a-button v-if="addEnable" type="primary" @click="toHandler('add')">新增</a-button>
        <!-- <a-button v-if="editEnable && selectedRows.length > 0" type="primary" @click="toHandler('edit')">修改</a-button> -->
        <a-button v-if="selectedRows.length == 1" type="primary" @click="toHandler('check')">查看</a-button>
        <!--<a-button v-if="selectedRows.length == 1 && selectedRows[0].orderStatus == 0 " type="primary" @click="toHandler('shenqing')">申请预定单</a-button>-->
        <a-button
          v-if="lostOrder && selectedRows.length > 0 && selectedRows[0].orderStatus == 1"
          type="primary"
          @click="loseEfficacyLost"
        >设为丢单</a-button>
        <a-button v-if="setDealt && selectedRows.length == 1 && (selectedRows[0].orderStatus == 0 || (selectedRows[0].orderStatus == 1 && selectedRows[0].verifyStatus == 2 ))" type="primary" @click="toHandler('set_dealt')"
          >设置已成交</a-button>
        <!--<a-button v-if="invalid && selectedRows.length > 0 && selectedRows[0].orderStatus != 3 && selectedRows[0].orderStatus != 4"type="primary" @click="loseEfficacy">设为失效</a-button>-->
<!--        <a-button-->
<!--          v-if="auint && selectedRows.length > 0 && selectedRows[0].verifyStatus == 0"-->
<!--          type="primary"-->
<!--          @click="loseEfficacyAuint"-->
<!--          >提交审核</a-button>-->
        <!--<a-button v-if="removeEnable && selectedRows.length > 0" type="danger" @click="toHandler('del')">删除</a-button>-->
        <a-button v-if="removeEnable" type="danger" @click="toHandler('del')">批量删除</a-button>
        <downLoad method="get" api="/api/dealer/customer/dealerCustomerOrder/excelExportCustomerOrder" :params="searchData" name="预订单信息.xls">导出预订单信息</downLoad>
      </div>
      <!-- 数据表格 -->
      <a-table
        :scroll="{ x: 1800 }"
        :loading="tableLoading"
        :row-selection="rowSelection"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="tableData"
        bordered
        :pagination="page"
        @change="changeTable"
        :customRow="changeTableRow"
        :rowClassName="rowClassName"
      >
      <span slot="chengJiaoProNum" slot-scope="text, row">
            <span v-if="text <= 0">未选择商品</span>
            <a-button v-else type="default" size="small" @click="checkGoods(row.id)" icon="eye">查看商品</a-button>
        </span>
        <span slot="index" slot-scope="text, row, index">{{ index + 1 }}</span>
        <span slot="propertyType" slot-scope="text, row">
          <a-tag  v-if="row.propertyType == 0" color="green">
            商品房
          </a-tag>
          <a-tag v-if="row.propertyType == 1" color="orange">
           自建房
          </a-tag>
        </span>
        <span slot="orderType" slot-scope="text, row">
         <a-tag v-if="row.orderType == 1" color="#f50">
            零售
          </a-tag>
          <a-tag v-if="row.orderType == 2" color="#108ee9">
            项目
          </a-tag>
        </span>
        <span slot="orderStatus" slot-scope="text">
          <span v-if="text === 0">未申请</span>
          <span v-if="text === 1">已申请</span>
          <span v-if="text === 2">已成交</span>
          <span v-if="text === 3">失效</span>
          <span v-if="text === 4">丢单</span>
          <span v-if="text === 5">无效</span>
          <span v-if="text === 6">过期</span>
        </span>
        <span slot="registrationStatus" slot-scope="text">
          <span v-if="text === 0">未申请</span>
          <span v-if="text === 1">首次申请</span>
          <span v-if="text === 2">二次申请</span>
          <span v-if="text === 3">申请成功</span>
        </span>
        <span slot="verifyStatus" slot-scope="text">
          <a-tag v-if="text == 0" color="#87d068">待处理</a-tag>
          <a-tag v-if="text == 1" color="#e6a23c">待审核</a-tag>
          <a-tag v-if="text == 2" color="#108ee9">审核通过</a-tag>
          <a-tag v-if="text == 3" color="#ff7875">审核拒绝</a-tag>
          <a-tag v-if="text == 99" color="#e6a23c">审核中</a-tag>
      </span>
        <span slot="sourceType" slot-scope="text">
          <a-tag v-if="text == 0">PC端</a-tag>
          <a-tag v-if="text == 1">小程序</a-tag>
        </span>
      <span slot="action" slot-scope="text, record">
        <a @click="checkLog(record)">查看日志</a>
      </span>
      </a-table>
      <!-- 编辑表单 -->
      <DealerCustomerOrder-edit-modal
        ref="DealerCustomerOrderEditModal"
        @reload="getData"
      ></DealerCustomerOrder-edit-modal>
       <DealerCustomerInfoModal ref="DealerCustomerInfoModal"></DealerCustomerInfoModal>
      <SetDealtModal ref="SetDealtModal" @reload="getData"></SetDealtModal>
       <CheckGoods ref="CheckGoods"></CheckGoods>
    </div>

    <!-- 失效 -->
    <a-modal
      v-model="visible"
      title="设置失效"
      @ok="handleOkAll"
      @cancel="handleCanceAll"
      :maskClosable="false"
      width="40%"
      :confirm-loading="confirmLoading"
    >
      <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 15 }">
        <a-form-model-item label="备注">
          <a-textarea v-model="orderRemarks"></a-textarea>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!-- 丢单 -->
    <a-modal
      v-model="visibleLost"
      title="设置丢单"
      @ok="handleOkLost"
      @cancel="handleCanceLost"
      :maskClosable="false"
      width="40%"
      :confirm-loading="confirmLoadingLost"
    >
      <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 15 }">
        <a-form-model-item label="备注">
          <a-textarea v-model="orderRemarksLost"></a-textarea>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!-- 审核 -->
    <a-modal
      v-model="visibleAuint"
      title="提交审核"
      @ok="handleOkAuint"
      @cancel="handleCanceAuint"
      :maskClosable="false"
      width="40%"
      :confirm-loading="confirmLoadingAuint"
    >
      <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 15 }">
        <a-form-model-item label="备注">
          <a-textarea v-model="verifyRemark"></a-textarea>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal title="查看日志" :maskClosable="false" v-model="visibleLog" width="50%" :footer="null">
      <a-table
        :rowKey="(record) => record.id"
        :columns="columnsCheck"
        :data-source="tableDataCheck"
        bordered
        :pagination="false"
      >
      </a-table>
    </a-modal>
  </a-card>
</template>

<script>
import { columns } from './components/colums.js'
import DealerCustomerOrderEditModal from '../dealer_customer_info/components/DealerCustomerInfoEditModal.vue'
import DealerCustomerInfoModal from '../dealer_customer_info/components/DealerCustomerInfoModal.vue'
import CheckGoods from './components/CheckGoods.vue'
import {
  delDealerCustomerOrder,
  listDealerCustomerOrder,
  selectByIdDealerCustomerOrder,
} from './api/DealerCustomerOrderApi'
import { checkPermission } from '@/utils/permissions'
import SetDealtModal from '@/views/dealer_customer_order/components/SetDealtModal.vue'
import {
  batchQuXiaoDealerCustomerInfo,
  doShenQingDealerCustomerOrder
} from "@/views/dealer_customer_info/api/DealerCustomerInfoApi";

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'dealerCustomerOrder',
  components: {
    DealerCustomerOrderEditModal,
    SetDealtModal,
    DealerCustomerInfoModal,
    CheckGoods
  },
  data() {
    return {
      confirmLoadingAuint: false,
      visibleAuint: false,
      statusAuint: 3,
      verifyRemark: '',
      ids: [],
      columns: columns,
      visible: false,
      visibleLost: false,
      confirmLoadingLost: false,
      orderRemarksLost: '',
      orderRemarks: '',
      confirmLoading: false,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      visibleLog: false,
      tableDataCheck:[],
      columnsCheck: [
        {
          title: '客户',
          dataIndex: 'cusName',
          key: 'cusName',
          width:100,
          align: 'center',
          ellipsis: true,
        },
        {
          title: '操作人',
          dataIndex: 'createUser',
          key: 'createUser',
          width:100,
          align: 'center',
          ellipsis: true,
        },
        {
          title: '操作时间',
          dataIndex: 'createTime',
          key: 'createTime',
          width:150,
          align: 'center',
          ellipsis: true,
        },
        {
          title: '操作日志',
          dataIndex: 'log',
          key: 'log',
          width:150,
          align: 'center',
          ellipsis: true,
        },
      ],
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      managerData: [],
      addEnable: checkPermission('dealer:customer_order:add'),
      editEnable: checkPermission('dealer:customer_order:edit'),
      removeEnable: checkPermission('dealer:customer_order:remove'),
      setDealt: checkPermission('dealer:customer:set_dealt'),
      invalid: checkPermission('dealer:customer:in_valid'),
      lostOrder: checkPermission('dealer:customer:lost_order'),
      auint: checkPermission('dealer:customer:auint'),
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        // type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },
  methods: {
    // 查看日志
    checkLog(row) {
      this.visibleLog = true
      this.axios.get('/api/dealer/quarters/dealerCustomerOrderLog/listByCusId/' + row.cusId).then((res) => {
        if(res.body){
          this.tableDataCheck=res.body
        }
      })
    },

     checkGoods(id){
      this.$refs.CheckGoods.isShow(id)
    },
    // 提交审核
    loseEfficacyAuint() {
      if (this.selectedRows.length == 0) {
        return this.$message.warning('请至少选择一条数据')
      }
      this.visibleAuint = true
      if(this.selectedRows.length >0){
      let arrList = this.selectedRows.filter(item =>{
        return item.verifyStatus === 0
      })
      if(arrList){
      this.ids = arrList.map((item) => {
        return item.id
      })
      }else{
        this.$message.warning('请选择未处理状态的数据')
      }
      }
    },
    handleOkAuint() {
      this.confirmLoadingAuint = true
      let obj = {
        verifyRemark: this.verifyRemark,
        ids: this.ids,
      }
        this.axios
          .post('/api/dealer/customer/dealerCustomerOrder/verifyStatus', obj)
          .then((res) => {
            this.$message.success(res.message)
            this.visibleAuint = false
            this.verifyRemark = ''
            this.getData()
          })
          .finally(() => {
            this.confirmLoadingAuint = false
          })
    },
    handleCanceAuint() {
      this.visibleAuint = false
      this.confirmLoadingAuint = false
      this.verifyRemark = ''
    },

    // 设为丢单
    loseEfficacyLost() {
      if (this.selectedRowKeys.length == 0 || this.selectedRowKeys.length > 1) {
        return this.$message.warning('请选择一条数据')
      }
      this.visibleLost = true
    },

    handleCanceLost() {
      this.visibleLost = false
      this.confirmLoadingLost = false
      this.orderRemarksLost = ''
    },
    handleOkLost() {
      this.confirmLoadingLost = true
      let obj = {
        orderRemarks: this.orderRemarksLost,
        id: this.selectedRows[0].cusId,
      }
      this.axios
        .post('/api/dealer/dealer/customerInfo/doLossOrderForDealer', obj)
        .then((res) => {
          this.$message.success(res.message)
          this.visibleLost = false
          this.orderRemarksLost = ''
          this.selectedRowKeys = []
          this.selectedRows = []
          this.getData()
        })
        .finally(() => {
          this.confirmLoadingLost = false
        })
    },

    // 设为失效
    loseEfficacy() {
      if (this.selectedRowKeys.length == 0 || this.selectedRowKeys.length > 1) {
        return this.$message.warning('请选择一条数据')
      }
      this.visible = true
    },
    handleOkAll() {
      this.confirmLoading = true
      let obj = {
        orderRemarks: this.orderRemarks,
        id: this.selectedRows[0].id,
      }
      this.axios
        .post('/api/dealer/customer/dealerCustomerOrder/updateOrder', obj)
        .then((res) => {
          this.$message.success(res.message)
          this.visible = false
          this.orderRemarks = ''
          this.getData()
        })
        .finally(() => {
          this.confirmLoading = false
        })
    },
    handleCanceAll() {
      this.visible = false
      this.confirmLoading = false
      this.orderRemarks = ''
    },
    /**
     * 获取表格数据
     */
    getData() {
      this.tableLoading = true
      this.selectedRowKeys = []
      this.selectedRows = []
      listDealerCustomerOrder({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
      this.axios.get(`/api/base/system/dealer/manager/managerListForDealer`).then((res) => {
        if (res.code == 200) {
          this.managerData = res.body
        }
      })
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      // this.page = Object.assign({}, this.page)
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      // this.page = Object.assign({}, this.page)
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    // 已失效置灰
    rowClassName(record, index) {
      let className;
      if (record.orderStatus === 3) className = 'dark-row'
      return className
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },

    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.DealerCustomerOrderEditModal.setRowData({}, 'add')
        return
      }
      if (this.selectedRowKeys.length == 0 || this.selectedRowKeys.length > 1) {
        return this.$message.warning('请选择一条数据')
      }
      switch (name) {
        case 'edit':
          _this.$refs.DealerCustomerOrderEditModal.setRowData(_this.selectedRows[0], 'edit')
          break
          case 'check':
          _this.$refs.DealerCustomerInfoModal.setRowData(_this.selectedRows[0], 'check','order')
          break
        case 'del':
          let arr = []
          for (var i = 0; i < this.selectedRows.length; i++) {
            if (arr.indexOf(this.selectedRows[i].orderStatus) === -1) {
              arr.push(this.selectedRows[i].orderStatus)
            }
          }

          if (arr.indexOf(2) >= 0 || arr.indexOf(3) >= 0 ||arr.indexOf(4) >= 0 ) {
            return this.$message.warning('选中的数据中包含不可删除的记录')
          }
          const cusIds = this.selectedRows.map((item) => item.cusId);

          _this.$confirm({
            title: '删除警告',
            content: '确认要删除选中记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await batchQuXiaoDealerCustomerInfo(cusIds)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'shenqing':
          _this.$confirm({
            title: '申请提醒',
            content: '确认要为此客户申请预定单吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await doShenQingDealerCustomerOrder({id:_this.selectedRows[0].cusId})
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.selectedRowKeys = []
                _this.selectedRows = []
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'set_dealt':
          _this.$refs.SetDealtModal.show(_this.selectedRows[0],_this.selectedRows[0].cusId)
          break
      }
    },
  },
  created() {
    this.getData()
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
</style>
